import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { supabase } from './lib/supabase';
import { useAuthStore } from './store/auth';

// Initialize auth state from Supabase session
const initAuth = async () => {
  const { data: { session } } = await supabase.auth.getSession();
  
  if (session?.user) {
    const { data: profile } = await supabase
      .from('users')
      .select('*, company:companies(*)')
      .eq('id', session.user.id)
      .single();

    if (profile) {
      useAuthStore.setState({
        user: {
          id: profile.id,
          email: profile.email,
          name: profile.name,
          role: profile.role,
          companyId: profile.company_id,
        },
        isAuthenticated: true,
      });
    }
  }
};

// Setup auth state change listener
supabase.auth.onAuthStateChange(async (event, session) => {
  if (event === 'SIGNED_IN' && session?.user) {
    const { data: profile } = await supabase
      .from('users')
      .select('*, company:companies(*)')
      .eq('id', session.user.id)
      .single();

    if (profile) {
      useAuthStore.setState({
        user: {
          id: profile.id,
          email: profile.email,
          name: profile.name,
          role: profile.role,
          companyId: profile.company_id,
        },
        isAuthenticated: true,
      });
    }
  } else if (event === 'SIGNED_OUT') {
    useAuthStore.setState({ user: null, isAuthenticated: false });
  }
});

initAuth()
  .then(() => {
    createRoot(document.getElementById('root')!).render(
      <StrictMode>
        <App />
      </StrictMode>
    );
  })
  .catch(error => {
    console.error('Failed to initialize the application:', error);
    document.getElementById('root')!.innerHTML = `
      <div style="padding: 20px; text-align: center;">
        <h1>Application Error</h1>
        <p>Failed to initialize the application. Please try refreshing the page.</p>
        <pre style="margin-top: 20px; text-align: left; background: #f5f5f5; padding: 10px; border-radius: 4px;">
          ${error.message}
        </pre>
      </div>
    `;
  });