import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Layout } from './components/layout/Layout';
import { LoginPage } from './pages/auth/LoginPage';
import { RegisterPage } from './pages/auth/RegisterPage';
import { DashboardPage } from './pages/DashboardPage';
import { InvoicesPage } from './pages/invoices/InvoicesPage';
import { NewInvoicePage } from './pages/invoices/NewInvoicePage';
import { CustomersPage } from './pages/customers/CustomersPage';
import { SettingsPage } from './pages/settings/SettingsPage';
import { ProtectedRoute } from './components/auth/ProtectedRoute';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route element={<ProtectedRoute />}>
          <Route element={<Layout />}>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/facturen" element={<InvoicesPage />} />
            <Route path="/facturen/nieuw" element={<NewInvoicePage />} />
            <Route path="/klanten" element={<CustomersPage />} />
            <Route path="/instellingen" element={<SettingsPage />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}