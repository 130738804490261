import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import { registerSchema, type RegisterFormData } from '../../lib/validation/auth';
import { useAuthStore } from '../../store/auth';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { FormField } from '../common/FormField';

export function RegisterForm() {
  const navigate = useNavigate();
  const register = useAuthStore((state) => state.register);
  
  const {
    register: registerField,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<RegisterFormData>({
    resolver: zodResolver(registerSchema),
  });

  const onSubmit = async (data: RegisterFormData) => {
    try {
      await register(data);
      navigate('/');
    } catch (error) {
      console.error('Registration failed:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <FormField
        label="Company Name"
        error={errors.companyName?.message}
      >
        <Input
          {...registerField('companyName')}
          type="text"
          autoComplete="organization"
        />
      </FormField>

      <FormField
        label="Full Name"
        error={errors.name?.message}
      >
        <Input
          {...registerField('name')}
          type="text"
          autoComplete="name"
        />
      </FormField>

      <FormField
        label="Email Address"
        error={errors.email?.message}
      >
        <Input
          {...registerField('email')}
          type="email"
          autoComplete="email"
        />
      </FormField>

      <FormField
        label="Password"
        error={errors.password?.message}
      >
        <Input
          {...registerField('password')}
          type="password"
          autoComplete="new-password"
        />
      </FormField>

      <FormField
        label="Confirm Password"
        error={errors.confirmPassword?.message}
      >
        <Input
          {...registerField('confirmPassword')}
          type="password"
          autoComplete="new-password"
        />
      </FormField>

      <Button
        type="submit"
        className="w-full"
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Creating account...' : 'Create account'}
      </Button>
    </form>
  );
}