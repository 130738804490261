import { create } from 'zustand';
import { login as apiLogin, register as apiRegister, logout as apiLogout } from '../lib/api/auth';
import type { User } from '../types';
import type { RegisterFormData } from '../lib/validation/auth';

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<void>;
  register: (data: RegisterFormData) => Promise<void>;
  logout: () => Promise<void>;
}

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  isAuthenticated: false,
  login: async (email: string, password: string) => {
    const user = await apiLogin(email, password);
    if (!user) {
      throw new Error('Invalid credentials');
    }
    set({ user, isAuthenticated: true });
  },
  register: async (data: RegisterFormData) => {
    const { user } = await apiRegister(data);
    set({ user, isAuthenticated: true });
  },
  logout: async () => {
    await apiLogout();
    set({ user: null, isAuthenticated: false });
  },
}));