import { supabase } from '../supabase';
import type { User } from '../../types';
import type { RegisterFormData } from '../validation/auth';

export async function login(email: string, password: string): Promise<User | null> {
  const { data: { user }, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  if (error || !user) return null;

  const { data: profile } = await supabase
    .from('users')
    .select('*, company:companies(*)')
    .eq('id', user.id)
    .single();

  if (!profile) return null;

  return {
    id: profile.id,
    email: profile.email,
    name: profile.name,
    role: profile.role,
    companyId: profile.company_id,
  };
}

export async function register(data: RegisterFormData) {
  // Create auth user
  const { data: { user }, error: authError } = await supabase.auth.signUp({
    email: data.email,
    password: data.password,
  });

  if (authError || !user) throw authError;

  // Create company
  const { data: company, error: companyError } = await supabase
    .from('companies')
    .insert({
      name: data.companyName,
      address: '',
      phone: '',
      email: data.email,
      vat_number: '',
    })
    .select()
    .single();

  if (companyError || !company) throw companyError;

  // Create user profile
  const { data: profile, error: profileError } = await supabase
    .from('users')
    .insert({
      id: user.id,
      name: data.name,
      email: data.email,
      role: 'admin',
      company_id: company.id,
    })
    .select('*, company:companies(*)')
    .single();

  if (profileError || !profile) throw profileError;

  return { user: profile, company };
}

export async function logout() {
  await supabase.auth.signOut();
}