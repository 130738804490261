import { supabase } from '../supabase';
import type { Customer } from '../../types';

export async function getCustomers(companyId: string): Promise<Customer[]> {
  const { data, error } = await supabase
    .from('customers')
    .select('*')
    .eq('company_id', companyId);

  if (error) throw error;
  
  return data.map(customer => ({
    id: customer.id,
    companyId: customer.company_id,
    name: customer.name,
    email: customer.email,
    address: customer.address,
    phone: customer.phone,
    vatNumber: customer.vat_number,
  }));
}

export async function createCustomer(customer: Omit<Customer, 'id'>): Promise<Customer> {
  const { data, error } = await supabase
    .from('customers')
    .insert({
      company_id: customer.companyId,
      name: customer.name,
      email: customer.email,
      address: customer.address,
      phone: customer.phone,
      vat_number: customer.vatNumber,
    })
    .select()
    .single();

  if (error || !data) throw error;

  return {
    id: data.id,
    companyId: data.company_id,
    name: data.name,
    email: data.email,
    address: data.address,
    phone: data.phone,
    vatNumber: data.vat_number,
  };
}

export async function updateCustomer(id: string, updates: Partial<Customer>): Promise<void> {
  const { error } = await supabase
    .from('customers')
    .update({
      name: updates.name,
      email: updates.email,
      address: updates.address,
      phone: updates.phone,
      vat_number: updates.vatNumber,
    })
    .eq('id', id);

  if (error) throw error;
}

export async function deleteCustomer(id: string): Promise<void> {
  const { error } = await supabase
    .from('customers')
    .delete()
    .eq('id', id);

  if (error) throw error;
}