import { Link } from 'react-router-dom';
import { Users, FileText, Download, Mail, Plus, CreditCard, TrendingUp } from 'lucide-react';

export function DashboardPage() {
  const stats = [
    {
      title: 'Totaal Klanten',
      value: '2,543',
      change: '+12%',
      icon: Users,
      isPositive: true,
      iconBackground: 'bg-blue-50',
      iconColor: 'text-blue-600',
    },
    {
      title: 'Facturen',
      value: '1,259',
      change: '+8%',
      icon: FileText,
      isPositive: true,
      iconBackground: 'bg-green-50',
      iconColor: 'text-green-600',
    },
    {
      title: 'Omzet',
      value: '€45,678',
      change: '-3%',
      icon: CreditCard,
      isPositive: false,
      iconBackground: 'bg-purple-50',
      iconColor: 'text-purple-600',
    },
    {
      title: 'Openstaand',
      value: '€12,345',
      change: '+5%',
      icon: TrendingUp,
      isPositive: true,
      iconBackground: 'bg-yellow-50',
      iconColor: 'text-yellow-600',
    },
  ];

  const recentInvoices = [
    { id: 'F2024-0001', company: 'Bedrijf 1 BV', amount: 2400, date: '28 Nov 2024', status: 'concept' },
    { id: 'F2024-0002', company: 'Bedrijf 2 BV', amount: 1800, date: '27 Nov 2024', status: 'verzonden' },
    { id: 'F2024-0003', company: 'Bedrijf 3 BV', amount: 3200, date: '26 Nov 2024', status: 'betaald' },
  ];

  const quickActions = [
    {
      title: 'Nieuwe Factuur',
      description: 'Maak een nieuwe factuur aan',
      icon: Plus,
      href: '/facturen/nieuw',
      color: 'bg-blue-500 hover:bg-blue-600',
      primary: true,
    },
    {
      title: 'Concept Facturen',
      description: 'Beheer conceptfacturen',
      icon: FileText,
      href: '/facturen?status=draft',
      color: 'bg-white hover:bg-gray-50',
      border: true,
    },
    {
      title: 'Nieuwe Klant',
      description: 'Voeg een nieuwe klant toe',
      icon: Users,
      href: '/klanten/nieuw',
      color: 'bg-white hover:bg-gray-50',
      border: true,
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
          <Link
            to="/facturen/nieuw"
            className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            <Plus className="w-5 h-5 mr-2" />
            Nieuwe Factuur
          </Link>
        </div>

        {/* Snelle Acties */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          {quickActions.map((action) => (
            <Link
              key={action.title}
              to={action.href}
              className={`p-6 rounded-xl ${
                action.primary
                  ? 'bg-blue-600 text-white hover:bg-blue-700'
                  : 'bg-white hover:bg-gray-50 border border-gray-200'
              } shadow-sm transition-all hover:shadow-md`}
            >
              <div className="flex items-center">
                <div className={`p-3 rounded-lg ${action.primary ? 'bg-blue-500' : 'bg-gray-100'}`}>
                  <action.icon className={`h-6 w-6 ${action.primary ? 'text-white' : 'text-gray-600'}`} />
                </div>
                <div className="ml-4">
                  <h3 className={`text-lg font-medium ${action.primary ? 'text-white' : 'text-gray-900'}`}>
                    {action.title}
                  </h3>
                  <p className={`text-sm ${action.primary ? 'text-blue-100' : 'text-gray-500'}`}>
                    {action.description}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4 mb-8">
          {stats.map((stat) => (
            <div key={stat.title} className="bg-white rounded-xl shadow-sm p-6 hover:shadow-md transition-shadow">
              <div className="flex items-center">
                <div className={`${stat.iconBackground} p-3 rounded-lg`}>
                  <stat.icon className={`h-6 w-6 ${stat.iconColor}`} />
                </div>
                <div className="ml-4">
                  <h3 className="text-sm font-medium text-gray-500">{stat.title}</h3>
                  <div className="mt-1">
                    <p className="text-2xl font-semibold text-gray-900">{stat.value}</p>
                    <p className={`text-sm ${stat.isPositive ? 'text-green-600' : 'text-red-600'}`}>
                      {stat.change} vs vorige maand
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Recente Facturen */}
        <div className="bg-white rounded-xl shadow-sm">
          <div className="px-6 py-5 border-b border-gray-200 flex justify-between items-center">
            <h2 className="text-lg font-medium text-gray-900">Recente Facturen</h2>
            <div className="flex space-x-2">
              <button className="p-2 text-gray-400 hover:text-gray-600 rounded-md hover:bg-gray-50">
                <Download className="w-5 h-5" />
              </button>
              <button className="p-2 text-gray-400 hover:text-gray-600 rounded-md hover:bg-gray-50">
                <Mail className="w-5 h-5" />
              </button>
            </div>
          </div>
          <div className="divide-y divide-gray-200">
            {recentInvoices.map((invoice) => (
              <div key={invoice.id} className="px-6 py-4 hover:bg-gray-50 transition-colors">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm font-medium text-gray-900">{invoice.company}</p>
                    <p className="text-sm text-gray-500">{invoice.id}</p>
                  </div>
                  <div className="text-right">
                    <p className="text-sm font-medium text-gray-900">€{invoice.amount.toLocaleString('nl-NL')}</p>
                    <p className="text-sm text-gray-500">{invoice.date}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}