import { supabase } from '../supabase';
import type { Company } from '../../types';

export async function getCompany(id: string): Promise<Company | null> {
  const { data, error } = await supabase
    .from('companies')
    .select('*')
    .eq('id', id)
    .single();

  if (error || !data) return null;

  return {
    id: data.id,
    name: data.name,
    address: data.address,
    phone: data.phone,
    email: data.email,
    vatNumber: data.vat_number,
    logo: data.logo,
  };
}

export async function updateCompany(id: string, updates: Partial<Company>): Promise<void> {
  const { error } = await supabase
    .from('companies')
    .update({
      name: updates.name,
      address: updates.address,
      phone: updates.phone,
      email: updates.email,
      vat_number: updates.vatNumber,
      logo: updates.logo,
    })
    .eq('id', id);

  if (error) throw error;
}