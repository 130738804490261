import { type InputHTMLAttributes, forwardRef, useState } from 'react';
import { cn } from '../../lib/utils';
import { Upload } from 'lucide-react';

interface FileInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'value' | 'onChange'> {
  error?: string;
  value?: string;
  onChange?: (base64: string | null) => void;
  maxSize?: number; // in MB
}

export const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  ({ className, error, onChange, value, maxSize = 2, ...props }, ref) => {
    const [preview, setPreview] = useState<string | null>(value || null);

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) {
        setPreview(null);
        onChange?.(null);
        return;
      }

      // Validate file size
      if (file.size > maxSize * 1024 * 1024) {
        alert(`File size must be less than ${maxSize}MB`);
        return;
      }

      // Validate file type
      if (!file.type.startsWith('image/')) {
        alert('Only image files are allowed');
        return;
      }

      // Convert to base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result as string;
        setPreview(base64);
        onChange?.(base64);
      };
      reader.readAsDataURL(file);
    };

    return (
      <div className="space-y-2">
        <div
          className={cn(
            'relative flex items-center justify-center w-full h-40 border-2 border-dashed rounded-lg',
            'hover:bg-gray-50 transition-colors cursor-pointer',
            error ? 'border-red-500' : 'border-gray-300',
            className
          )}
        >
          <input
            ref={ref}
            type="file"
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            accept="image/*"
            onChange={handleChange}
            {...props}
          />
          {preview ? (
            <img
              src={preview}
              alt="Logo preview"
              className="max-h-full max-w-full object-contain"
            />
          ) : (
            <div className="text-center">
              <Upload className="mx-auto h-12 w-12 text-gray-400" />
              <p className="mt-2 text-sm text-gray-600">
                Click or drag and drop to upload your logo
              </p>
              <p className="text-xs text-gray-500">PNG, JPG up to {maxSize}MB</p>
            </div>
          )}
        </div>
        {error && <p className="text-sm text-red-500">{error}</p>}
      </div>
    );
  }
);